import { Link } from "react-router-dom";
import MainArticleBackgroundImage from "./components/RelationCenterMain/MainArticleBackgroundImage";
import ImageRotator from "./components/RelationCenterMain/ImageRotator";
import Icon from "./components/Icon";

import styles from "./RelationCenter.scss";
import classNames from "classnames";
import { imageURL } from "./libs/image";
const cx = classNames.bind(styles);

const ROTATE_IMAGES = [
  imageURL + "/hongbo-main-bg-1.jpg",
  imageURL + "/hongbo-main-bg-2.jpg",
  imageURL + "/hongbo-main-bg-3.jpg",
];

const RelationCenter = () => {
  return (
    <main className={cx("relation-center-main")}>
      <ImageRotator
        srcs={ROTATE_IMAGES}
        delay={5000}
        duration={2000}
        className={cx("image-rotator")}
      />
      <div className={cx("container")}>
        <figure className={cx("background-figure")}>
          <img
            src={process.env.REACT_APP_IMAGE_URL + "/logo_white.png"}
            alt=""
          />
        </figure>
        <article className={cx("overlay-text")}>
          <h1 className={cx("header1")}>서울야고보 온라인 홍보관</h1>
          <p className={cx("paragraph")}>세상의 빛과 소금이 되기를 바라며!</p>
          <p className={cx("paragraph")}>
            나라 사랑, 이웃 사랑에 앞장서는 서울야고보
          </p>
        </article>
        <Link to="/relation-center/history" className={cx("link-container")}>
          <MainArticleBackgroundImage
            src={imageURL + "/hongbo-main-1.jpg"}
            className={cx("blue-image-hover")}
          />
          <span className={cx("text")}>홍보관</span>
          <span className={cx("icon")}>
            <Icon icon="arrowRightRound" />
          </span>
        </Link>
        <Link
          to="/relation-center/reservation"
          className={cx("link-container")}
        >
          <MainArticleBackgroundImage
            src={imageURL + "/hongbo-main-2.jpg"}
            className={cx("blue-image-hover")}
          />
          <span className={cx("text")}>예약하기</span>
          <span className={cx("icon")}>
            <Icon icon="arrowRightRound" />
          </span>
        </Link>
        <Link to="/" className={cx("link-container")}>
          <MainArticleBackgroundImage
            src={process.env.REACT_APP_IMAGE_URL + "/buliding_main.jpg"}
            className={cx("blue-image-hover")}
          />
          <span className={cx("text")}>지파소개</span>
          <span className={cx("icon")}>
            <Icon icon="arrowRightRound" />
          </span>
        </Link>
      </div>
    </main>
  );
};

export default RelationCenter;

export type DatepickerBase<T extends Record<string, any> = {}> = {
  getDate: () => Date;
  addDate: (number: number) => DatepickerBase<T>;
  addMonth: (number: number) => DatepickerBase<T>;
  addYear: (number: number) => DatepickerBase<T>;
  addHour: (number: number) => DatepickerBase<T>;
  addMinute: (number: number) => DatepickerBase<T>;
  addSecond: (number: number) => DatepickerBase<T>;
  addMillisecond: (number: number) => DatepickerBase<T>;
  setHour: (number: number) => DatepickerBase<T>;
  setMinute: (number: number) => DatepickerBase<T>;
  setSecond: (number: number) => DatepickerBase<T>;
  setMillisecond: (number: number) => DatepickerBase<T>;
  setDate: (date: Date) => DatepickerBase<T>;
  setMonth: (number: number) => DatepickerBase<T>;
  setMonthDate: (number: number) => DatepickerBase<T>;
} & T;

export const datepicker = <T extends Record<string, any> = {}>(
  initDate: Date,
  monad: T = {} as T
): DatepickerBase<T> => {
  const date = new Date(initDate);

  const base = {
    getDate: () => date,
    addDate: (number: number) => {
      const newDate = new Date(date);
      newDate.setDate(date.getDate() + number);
      return datepicker(newDate, monad);
    },
    addMonth: (number: number) => {
      const newDate = new Date(date);
      newDate.setMonth(date.getMonth() + number);
      return datepicker(newDate, monad);
    },
    addYear: (number: number) => {
      const newDate = new Date(date);
      newDate.setFullYear(date.getFullYear() + number);
      return datepicker(newDate, monad);
    },
    addHour: (number: number) =>
      datepicker(new Date(date.getTime() + number * 60 * 60 * 1000), monad),
    addMinute: (number: number) =>
      datepicker(new Date(date.getTime() + number * 60 * 1000), monad),
    addSecond: (number: number) =>
      datepicker(new Date(date.getTime() + number * 1000), monad),
    addMillisecond: (number: number) =>
      datepicker(new Date(date.getTime() + number), monad),
    setHour: (number: number) =>
      datepicker(new Date(date.setHours(number)), monad),
    setMinute: (number: number) =>
      datepicker(new Date(date.setMinutes(number)), monad),
    setSecond: (number: number) =>
      datepicker(new Date(date.setSeconds(number)), monad),
    setMillisecond: (number: number) =>
      datepicker(new Date(date.setMilliseconds(number)), monad),
    setMonthDate: (number: number) =>
      datepicker(new Date(date.setDate(number)), monad),
    setDate: (date: Date) => datepicker(new Date(date), monad),
    setMonth: (number: number) =>
      datepicker(new Date(date.setMonth(number)), monad),
    ...(monad ?? {}),
  };

  return base as unknown as DatepickerBase<T>;
};

import axios from "axios";
import { useState } from "react";

const BASE_URL =
  "https://script.google.com/macros/s/AKfycbynhl4zVhIXEedBje2Z8BFByvSWsVx6Udq1ePpaRmkgJZZg4ND78AZXGHfyPC-baPqW/exec";

// 전역 변수를 모듈 스코프로 제한
let submissionInProgress = false;

export interface ReservationForm {
  name: string;
  phoneNumber: string;
  address: string;
  date: Date;
  memo: string;
  visitType: string;
  isAllowed: boolean;
}

const useReservation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState<ReservationForm>({
    name: "",
    phoneNumber: "",
    address: "",
    date: new Date(),
    memo: "",
    visitType: "",
    isAllowed: false,
  });

  const handleRegist = async () => {
    if (submissionInProgress) {
      return {
        ok: false,
        message: "이미 예약 처리중입니다. 잠시만 기다려주세요.",
      };
    }

    try {
      // 폼 유효성 검사
      if (!form.isAllowed) {
        throw new Error("예약을 위해 개인정보 수집에 동의해주세요.");
      }

      if (!form.name.trim()) {
        throw new Error("이름을 입력해주세요.");
      }

      if (!form.phoneNumber.trim()) {
        throw new Error("연락처를 입력해주세요.");
      }

      if (form.date.getTime() < new Date().getTime()) {
        throw new Error("예약 가능한 날짜가 아닙니다.");
      }

      // 로딩 상태 설정
      setIsLoading(true);
      submissionInProgress = true;

      const response = await axios.post(
        BASE_URL,
        JSON.stringify({
          name: form.name.trim(),
          phoneNumber: form.phoneNumber.trim(),
          address: form.address.trim(),
          date: form.date,
          memo: form.memo.trim(),
          visitType: form.visitType,
        })
      );

      if (response.data.ok) {
        return {
          ok: true,
          message: "예약 신청이 완료되었습니다.",
        };
      }

      throw new Error("예약 처리 중 오류가 발생했습니다.");
    } catch (error: unknown) {
      if (error instanceof Error) {
        return {
          ok: false,
          message: error.message,
        };
      }
      return {
        ok: false,
        message: "알 수 없는 오류가 발생했습니다.",
      };
    } finally {
      setIsLoading(false);
      submissionInProgress = false;
    }
  };

  return {
    setForm,
    handleRegist,
    form,
    isLoading,
  };
};

export default useReservation;

import classNames from "classnames";
import { imageURL } from "../libs/image";
import useReservation, {
  ReservationForm,
} from "@entities/reservation/model/useReservation";
import { useDatepicker, datepicker } from "@shared/libs/date";
import { useEffect, useRef } from "react";

const THIS_YEAR = new Date().getFullYear();

const WEEK_DAYS = ["일", "월", "화", "수", "목", "금", "토"];

const NOT_ALLOWED_DAYS = [0];

const MIN_TIME = "11:00";
const NOT_ALLOWED_TIMES = [
  { start: "12:00", end: "12:59" },
  { start: "18:00", end: "18:59" },
];
const MAX_TIME = "20:00";
const TIME_GAP = "01:00";

type Times = `${string}:${string}`;

const TIMES: Times[] = [];

let hour = Number(MIN_TIME.split(":")[0]);
let minute = Number(MIN_TIME.split(":")[1]);
while (hour < Number(MAX_TIME.split(":")[0])) {
  const formattedHour = hour.toString().padStart(2, "0");
  const formattedMinute = minute.toString().padStart(2, "0");
  if (
    NOT_ALLOWED_TIMES.some(
      (time) => time.start === `${formattedHour}:${formattedMinute}`
    )
  ) {
    hour = Number(hour) + Number(TIME_GAP.split(":")[0]);
    minute = Number(minute) + Number(TIME_GAP.split(":")[1]);
    continue;
  }
  TIMES.push(`${formattedHour}:${formattedMinute}`);

  hour = Number(hour) + Number(TIME_GAP.split(":")[0]);
  minute = Number(minute) + Number(TIME_GAP.split(":")[1]);
  if (Number(minute) > 60) {
    hour = Number(hour) + 1;
    minute = Number(minute) - 60;
  }
}

const filterUpdateSelect = (date: Date) => {
  const now = new Date();
  let targetDate = datepicker(date)
    .setHour(0)
    .setMinute(0)
    .setSecond(0)
    .setMillisecond(0);

  // 과거 날짜인 경우 내일로 설정
  if (targetDate.getDate().getTime() < now.getTime()) {
    targetDate = datepicker(now).addDate(1);
  }

  // 3개월 이후인 경우 3개월 후의 마지막 날로 설정
  const threeMonthsLater = new Date();
  threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);
  threeMonthsLater.setHours(23, 59, 59, 999);
  if (targetDate.getDate().getTime() > threeMonthsLater.getTime()) {
    targetDate = datepicker(threeMonthsLater);
  }

  // 허용되지 않는 요일인 경우 다음 허용되는 날짜로 설정
  while (NOT_ALLOWED_DAYS.includes(targetDate.getDate().getDay())) {
    targetDate = targetDate.addDate(1);
  }

  return targetDate.getDate();
};

const Reservation = () => {
  const { form, setForm, handleRegist, isLoading } = useReservation();

  const formRef = useRef<HTMLFormElement>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const animate = () => {
      formRef.current?.animate(
        [{ opacity: 1 }, { opacity: 0.7 }, { opacity: 1 }],
        {
          duration: 1000,
          easing: "ease-in-out",
        }
      );
    };

    if (isLoading) {
      animate(); // 즉시 첫 애니메이션 실행
      intervalRef.current = setInterval(animate, 1000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [isLoading]);

  const monthDatepicker = useDatepicker({
    initDate: filterUpdateSelect(new Date()),
  });

  const selectDatepicker = useDatepicker({
    initDate: filterUpdateSelect(new Date()),
  });

  useEffect(() => {
    if (
      monthDatepicker.getDate().getMonth() !==
      selectDatepicker.getDate().getMonth()
    ) {
      monthDatepicker.update(
        monthDatepicker
          .setMonth(selectDatepicker.getDate().getMonth())
          .getDate()
      );
    }
  }, [monthDatepicker.getDate()]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const response = await handleRegist();
    if (response?.ok) {
      window.alert(response.message);
      window.history.back();
    } else {
      window.alert(response?.message);
    }
  };

  const handleChange =
    (key: keyof ReservationForm) =>
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      setForm({ ...form, [key]: e.target.value });
    };

  const handleChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, isAllowed: e.target.checked });
  };

  const addMonth = () => {
    monthDatepicker.update(monthDatepicker.addMonth(1).getDate());
    selectDatepicker.update(
      filterUpdateSelect(
        monthDatepicker
          .setMonth(monthDatepicker.getDate().getMonth() + 1)
          .setMonthDate(1)
          .getDate()
      )
    );
  };

  const diffMonth = () => {
    monthDatepicker.update(monthDatepicker.addMonth(-1).getDate());
    selectDatepicker.update(
      filterUpdateSelect(
        monthDatepicker
          .setMonth(monthDatepicker.getDate().getMonth() - 1)
          .setMonthDate(1)
          .getDate()
      )
    );
  };

  const setDate = (date: Date) => {
    monthDatepicker.update(monthDatepicker.setMonth(date.getMonth()).getDate());
    selectDatepicker.update(
      filterUpdateSelect(
        monthDatepicker
          .setMonth(monthDatepicker.getDate().getMonth())
          .setMonthDate(date.getDate())
          .getDate()
      )
    );
    setForm((prev) => ({ ...prev, date: date }));
  };
  const handleChangeTime =
    (hour: number, minute: number) =>
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      selectDatepicker.update(
        selectDatepicker.setHour(hour).setMinute(minute).getDate()
      );
      setForm((prev) => ({
        ...prev,
        date: selectDatepicker.setHour(hour).setMinute(minute).getDate(),
      }));
    };

  return (
    <>
      <main className="h-full w-full relative overflow-y-auto">
        <img
          src={imageURL + "/hongbo-main-bg-2.jpg"}
          className="absolute object-cover w-full h-full opacity-10 filter blur-5"
          alt=""
        />
        <form
          ref={formRef}
          className="mx-auto my-20 flex flex-col items-center gap-10 w-full max-w-4xl position-relative z-10 bg-black bg-opacity-80 rounded-3xl px-10 py-10 text-xl"
          style={{ backdropFilter: "blur(10px)" }}
          onSubmit={handleSubmit}
        >
          <h2 className="font-bold text-3xl">홍보관 예약 신청</h2>
          <legend className="grid grid-cols-[60px_1fr] text-left w-full gap-y-2 items-center">
            <p>방문 유형</p>
            <select
              value={form.visitType}
              onChange={handleChange("visitType")}
              className="text-black bg-white bg-opacity-80 rounded-md p-2"
            >
              <option value="외부 방문객">외부 방문객</option>
              <option value="내부 성도">내부 성도</option>
            </select>
            <p>이름</p>
            <input
              value={form.name}
              onChange={handleChange("name")}
              className="text-black bg-white bg-opacity-80 rounded-md p-2"
            ></input>
            <p>주소(구)</p>
            <input
              value={form.address}
              onChange={handleChange("address")}
              className="text-black bg-white bg-opacity-80 rounded-md p-2"
            ></input>
            <p>연락처</p>
            <input
              value={form.phoneNumber}
              onChange={handleChange("phoneNumber")}
              className="text-black bg-white bg-opacity-80 rounded-md p-2"
            ></input>
            <p>예약일</p>
            <article className="flex flex-col gap-10">
              <div className="flex items-center justify-between">
                <button
                  onClick={diffMonth}
                  disabled={monthDatepicker.getDate().getMonth() === 0}
                  type="button"
                  className={
                    monthDatepicker.getDate().getMonth() === 0
                      ? "text-gray-500"
                      : ""
                  }
                >
                  이전 달
                </button>
                <span className="font-bold">
                  {monthDatepicker.getDate().getMonth() + 1}월
                </span>
                <button
                  onClick={addMonth}
                  disabled={monthDatepicker.getDate().getMonth() === 11}
                  type="button"
                  className={
                    monthDatepicker.getDate().getMonth() === 11
                      ? "text-gray-500"
                      : ""
                  }
                >
                  다음 달
                </button>
              </div>
              <div className="grid grid-cols-7 text-center">
                {WEEK_DAYS.map((day) => (
                  <p key={day} className="text-gray-500">
                    {day}
                  </p>
                ))}
                {Array.from({ length: 42 }, (_, index) => {
                  const thisMonthFirstDay = new Date(
                    THIS_YEAR,
                    monthDatepicker.getDate().getMonth(),
                    1
                  ).getDay();

                  const date = datepicker(
                    new Date(THIS_YEAR, monthDatepicker.getDate().getMonth(), 1)
                  ).addDate(index - thisMonthFirstDay);

                  // 오늘 날짜의 시작 시간 (00:00:00)
                  const today = new Date();
                  today.setHours(0, 0, 0, 0);

                  // 3개월 후의 마지막 날짜
                  const threeMonthsLater = new Date();
                  threeMonthsLater.setMonth(threeMonthsLater.getMonth() + 3);
                  threeMonthsLater.setHours(23, 59, 59, 999);

                  const isDisabled =
                    date.getDate().getTime() < today.getTime() ||
                    date.getDate().getTime() > threeMonthsLater.getTime() ||
                    NOT_ALLOWED_DAYS.includes(date.getDate().getDay());

                  const isSelect =
                    date.getDate().getDate() ===
                      selectDatepicker.getDate().getDate() &&
                    date.getDate().getMonth() ===
                      selectDatepicker.getDate().getMonth();

                  const isThisMonth =
                    date.getDate().getMonth() ===
                    monthDatepicker.getDate().getMonth();

                  return (
                    <button
                      key={date.getDate().getTime()}
                      disabled={isDisabled}
                      onClick={() => setDate(date.getDate())}
                      type="button"
                      className={`p-4 ${
                        isDisabled
                          ? "text-gray-500"
                          : isSelect
                          ? "text-blue-500"
                          : isThisMonth
                          ? "text-white "
                          : "text-white opacity-60"
                      } ${
                        !isDisabled
                          ? "hover:bg-blue-500 hover:bg-opacity-50"
                          : ""
                      }`}
                    >
                      {date.getDate().getDate()}
                    </button>
                  );
                })}
              </div>
              <div className="grid grid-cols-4">
                {TIMES.map((time) => {
                  const [hour, minute] = time.split(":");
                  const [selectHour, selectMinute] = [
                    selectDatepicker.getDate().getHours(),
                    selectDatepicker.getDate().getMinutes(),
                  ];
                  const isSelected =
                    Number(hour) === selectHour &&
                    Number(minute) === selectMinute;

                  const now = new Date();
                  const selectedDateTime = new Date(selectDatepicker.getDate());
                  selectedDateTime.setHours(Number(hour), Number(minute), 0, 0);

                  const isToday =
                    now.getDate() === selectedDateTime.getDate() &&
                    now.getMonth() === selectedDateTime.getMonth() &&
                    now.getFullYear() === selectedDateTime.getFullYear();

                  const isDisabled =
                    isToday && selectedDateTime.getTime() <= now.getTime();
                  return (
                    <button
                      key={time}
                      type="button"
                      onClick={handleChangeTime(Number(hour), Number(minute))}
                      disabled={isDisabled}
                      className={`py-4 text-center ${
                        isDisabled
                          ? "text-gray-500"
                          : isSelected
                          ? "text-blue-500"
                          : "text-white"
                      } ${
                        !isDisabled
                          ? "hover:bg-blue-500 hover:bg-opacity-50"
                          : ""
                      }`}
                    >
                      {time}
                    </button>
                  );
                })}
              </div>
            </article>
            <p>문의사항</p>
            <textarea
              value={form.memo}
              onChange={handleChange("memo")}
            ></textarea>
          </legend>
          <legend className="flex flex-col text-left w-full gap-10">
            <label className="flex items-center gap-10 self-end">
              <p>개인정보 수집/이용 및 제 3자 제공에 동의</p>
              <input
                checked={form.isAllowed}
                onChange={handleChangeCheck}
                type="checkbox"
                className=" bg-white bg-opacity-80 rounded-md"
              />
            </label>
            <button className="bg-blue-500 text-white px-4 py-4 rounded-full text-center">
              홍보관 예약하기
            </button>
          </legend>
        </form>
      </main>
    </>
  );
};

export default Reservation;

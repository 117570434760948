import { useState } from "react";
import { datepicker, DatepickerBase } from "./datepicker";

type UseDatepickerProps = {
  initDate: Date;
};

type AddMonad = {
  update: (date: Date) => void;
  getKoreaDate: () => Date;
};

export const useDatepicker = ({
  initDate,
}: UseDatepickerProps): DatepickerBase<AddMonad> => {
  const [date, setDate] = useState(initDate);

  const dateMonad = datepicker<AddMonad>(date, {
    update: (date: Date) => setDate(date),
    getKoreaDate: (): Date =>
      new Date(
        dateMonad.getDate().toLocaleString("ko-KR", {
          timeZone: "Asia/Seoul",
        })
      ),
  });

  return dateMonad;
};
